<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          Experience {{ isEdit ? "Edit" : "Add" }}
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab :title="`Experience ${isEdit ? 'Edit' : 'Add'}`">
            <div class="p-2">
              <ValidationObserver
                ref="experienceInfoForm"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="experienceInfoForm"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="organisation_type"
                          class="required col-lg-12 col-md-12"
                          >Type of Healthcare Facility</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="org_type_id"
                              :value="profile.org_type_id"
                              :options="
                                options && options['organisation_type']
                                  ? options['organisation_type']
                                  : []
                              "
                              :multiple="false"
                              :clearable="false"
                              @input="handleChangeSelect"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="lbl_organisation_nature"
                          class="required col-lg-12 col-md-12"
                          >Is this a Private or Government facility ?</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                            <RadioButton
                              name="organisation_nature"
                              :value="profile.organisation_nature"
                              :options="
                                options && options['organisation_nature']
                                  ? options['organisation_nature']
                                  : []
                              "
                              :error="errors[0]"
                              @change="handleChangeRadio"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="facility_type"
                            class="required col-lg-12 col-md-12"
                            >Facility Details</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="organisation_additional_info"
                                :value="profile['organisation_additional_info']"
                                :options="
                                  options && options['organisation_additional_info']
                                    ? options['organisation_additional_info']
                                    : []
                                "
                                :multiple="false"
                                :clearable="false"
                                @input="handleChangeSelect"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="TypeisOther" lg="6">
                        <CRow class="row mb-3">
                          <label class="required col-lg-12 col-md-12"
                            >What Type of Facility Is This ? </label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="organisation_additional_info_other"
                                :value="profile.organisation_additional_info_other"
                                @input="handleInput"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_score"
                            class="required col-lg-12 col-md-12"
                          >
                            Location of Facility </label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="organisation_location_id"
                                :value="profile.organisation_location_id"
                                :options="
                                  options && options['country']
                                    ? options['country']
                                    : []
                                "
                                :clearable="false"
                                @input="handleChangeSelect"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_organisation_bed_count"
                            class="col-lg-12 col-md-12"
                            >Bed Count / Size of Facility</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="organisation_bedcount"
                              :value="profile.organisation_bedcount"
                              type="Number"
                              @input="handleInput"
                              :onkeydown="restrictedKeys"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_direct_report_count"
                            class="col-lg-12 col-md-12"
                            >Number of Staff Reporting Directly to You</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="direct_reports_count"
                              :value="profile.direct_reports_count"
                              type="Number"
                              @input="handleInput"
                              :onkeydown="restrictedKeys"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_is_current_org"
                            class="required col-lg-12 col-md-12"
                            >Is this Current Employment ?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <RadioButton
                                name="is_current_org"
                                :value="profile.is_current_org"
                                :options="
                                  options && options['boolean']
                                    ? options['boolean']
                                    : []
                                "
                                :error="errors[0]"
                                @change="handleChangeRadio"
                              />
                             </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="profile.is_current_org != null" md="4">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_start_month_year"
                            class="required col-lg-12 col-md-12"
                            >Start Month / year</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider name="dob" v-slot="{ errors }">
                              <TextInput
                                hidden
                                name="dob"
                                :value="candidateInfo.dob"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              rules="QualifiedDate:@dob|date_validate|required|startBefore:@end_month_year"
                              v-slot="{ errors }"
                            >
                              <DateInput
                                :onlyMonthAndYear="true"
                                name="start_month_year"
                                :value="profile.start_month_year"
                                @change="handleDatePickerChange"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="profile.is_current_org == false" md="4">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_end_month_year"
                            class="required col-lg-12 col-md-12"
                            >End Month / year</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider name="dob" v-slot="{ errors }">
                              <TextInput
                                hidden
                                name="dob"
                                :value="candidateInfo.dob"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              name="end_month_year"
                              rules="QualifiedDate:@dob|date_validate|required|startAfter:@start_month_year"
                              v-slot="{ errors }"
                            >
                              <DateInput
                                :onlyMonthAndYear="true"
                                name="end_month_year"
                                :value="profile.end_month_year"
                                @change="handleDatePickerChange"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="profile.is_current_org == false" md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_nursing_level"
                            class="col-lg-12 col-md-12 required"
                            >Level</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="level_id"
                                :value="profile.level_id"
                                :options="
                                  options && options['candidateLevel']
                                    ? options['candidateLevel']
                                    : []
                                "
                                @input="handleChangeSelect"
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :error="errors[0]"
                                :showTooltip="true"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="profile.is_current_org == false" md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_current_job"
                            class="col-lg-12 col-md-12"
                            >Job Title / Role</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="designation"
                              :value="profile.designation"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import EditSection from "./EditSection";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("QualifiedDate", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    let today_date = new Date();
    if (input_date > birth_date && input_date < today_date) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("QualifiedDate", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    let today_date = new Date();
    if (input_date > birth_date && input_date < today_date) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});
extend("startBefore", {
  params: ["end_month_year"],
  validate(value, { end_month_year }) {
    if (end_month_year) {
      let target_date = new Date(end_month_year);
      let input_date = new Date(value);
      if (input_date > target_date) {
        return false;
      }
    }
    return true;
  },
  message: "Given Start year should be lesser than End year!",
});
extend("startAfter", {
  params: ["start_month_year"],
  validate(value,  { start_month_year }) {
    if (start_month_year) {
      let target_date = new Date(start_month_year);
      let input_date = new Date(value);
      if (input_date < target_date) {
        return false;
      }
    }
    return true;
  },
  message: "Given End year should be greater than Start year!",
});

export default {
  mixins: [EditSection],
  props: {
    ExperienceInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    candidateInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      profile: {},
      errors: [],
      experienceInfoPayload: {},
      experience_id: null,
      other_additional_info: false,
      restrictedKeys:
        "javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))",
    };
  },
  computed: {
    ...mapGetters([
      "getCandidateExperiences",
      "getCandidateExpOrgTypes",
      "locationCountryInfo",
      "getOrgNature",
      "getOrgAdditionalInfo",
      "candidateLevelFilterByTypeCountry",
      "isFetchingFilter",
    ]),
    options() {
      return {
        organisation_type: this.getCandidateExpOrgTypes || [],
        country: this.locationCountryInfo || [],
        organisation_nature: this.getOrgNature || [],
        organisation_additional_info: this.getOrgAdditionalInfo || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        candidateLevel:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.candidateInfo?.candidate_type_id,
            country_id: null,
          }) || [],
      };
    },
    TypeisOther() {
      return (
        this.profile?.organisation_additional_info?.label.includes(
          "Other"
        ) || false
      );
    },
    isFetching() {
      if (!this.isFetchingFilter) {
        if (this.isEdit) {
          let data = this.ExperienceInfo;
          this.profile = {
            ...this.profile,
            org_type_id: {
              code: data?.organisation_type_id ? data?.organisation_type_id : null,
              label: data?.organisation_type ? data?.organisation_type : null
            },
            organisation_nature: data?.organisation_nature,
            organisation_additional_info: {
              code: data?.organisation_additional_info_id,
              label: data?.organisation_additional_info
            },
            organisation_additional_info_other: data?.organisation_additional_info_other
              ? data?.organisation_additional_info_other
              : null,
            organisation_location_id: {
              code: data?.organisation_location_id ? data?.organisation_location_id : null,
              label: data?.organisation_location ? data?.organisation_location : null
            },
            organisation_bedcount: data?.organisation_bedcount
              ? data?.organisation_bedcount
              : null,
            direct_reports_count: data?.direct_reports_count
              ? data?.direct_reports_count
              : null,
            is_current_org: data?.is_current_org,
            is_current_exp: data?.is_current_exp,
            designation: data?.designation,
            level_id: {
              code: data?.level_id,
              label: data?.level_name
            },
            start_month_year:
              data.start_year && data.start_month != "--"
                ? data.start_month < 10
                  ? `${data.start_year}-0${data.start_month}`
                  : `${data.start_year}-${data.start_month}`
                : null,
            end_month_year:
              data.end_year && data.end_month != "--"
                ? data.end_month < 10
                  ? `${data.end_year}-0${data.end_month}`
                  : `${data.end_year}-${data.end_month}`
                : null,
            designation: data.designation
              ? data.designation 
              : null,
            level: {
              label: data.level_name,
              code: data.level_id
            },
          };
          this.experience_id = data.experience_id;
        }
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.initFetchExperienceInfoOptions()
  },
  methods: {
    ...mapActions(["initFetchExperienceInfoOptions", "showToast"]),
    orgAdditionalInfo(data) {
      let preferredarr = [];
      let value = data.code
      preferredarr.push(value);
      return preferredarr;
    },
    async onSubmit() {
      const isValid = await this.$refs.experienceInfoForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: this.errors[0] || "please fill mandatory fields!",
        });
        return;
      }
      this.experienceInfoPayload = {
        organisation_type_id: this.profile?.org_type_id?.code
          ? this.profile?.org_type_id?.code
          : null,
        organisation_nature: this.profile?.organisation_nature !== "--"
          ? this.profile?.organisation_nature
          : null,
        organisation_additional_info: this.profile?.organisation_additional_info
          ? this.orgAdditionalInfo(this.profile?.organisation_additional_info)
          : null,
        organisation_additional_info_other: this.TypeisOther && this.profile?.organisation_additional_info_other !== "--"
          ? this.profile?.organisation_additional_info_other
          : null,
        organisation_location_id: this.profile?.organisation_location_id?.code !== "--"
          ? this.profile?.organisation_location_id?.code
          : null,
        organisation_bedcount: this.profile?.organisation_bedcount != "--"
          ? parseInt(this.profile?.organisation_bedcount)
          : null,
        direct_reports_count: this.profile?.direct_reports_count !== "--"
          ? parseInt(this.profile?.direct_reports_count)
          : null,
        is_current_org: this.profile?.is_current_org !== null
          ? this.profile?.is_current_org
          : null,
        start_month:
          this.profile?.start_month_year
            ? m(this.profile?.start_month_year).month() + 1
            : null,
        start_year:
          this.profile?.start_month_year
            ? m(this.profile?.start_month_year).year()
            : null,
        end_month:
          this.profile?.end_month_year
            ? m(this.profile?.end_month_year).month() + 1
            : null,
        end_year:
          this.profile?.end_month_year
            ? m(this.profile?.end_month_year).year()
            : null,
        designation: this.profile?.designation !== null
          ? this.profile?.designation
          : null,
        level_id: this.profile?.level_id?.code !== null
          ? this.profile?.level_id?.code
          : null
      };
      if (this.isEdit) {
         this.experienceInfoPayload = {
          ...this.experienceInfoPayload,
          experience_id: this.experience_id
        }
        this.modalCallBack(true, this.experienceInfoPayload);
      } else {
        this.modalCallBack(true, this.experienceInfoPayload);
      }
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
};
</script>