<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">
          License Information {{ isEdit ? "Edit" : "Add" }}
        </h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          v-if="activeTab === 1"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <CTabs variant="tabs" :active-tab.sync="activeTab">
          <CTab title="CV / Resume Preview">
            <div class="p-2">
              <CVPreview />
            </div>
          </CTab>
          <CTab :title="`License Information ${isEdit ? 'Edit' : 'Add'}`">
            <div class="p-2">
              <ValidationObserver
                ref="editLicenseInfo"
                v-slot="{ handleSubmit }"
              >
                <form
                  @submit.prevent="handleSubmit(onSubmit)"
                  name="editLicenseInfo"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_country_id"
                          class="required col-lg-12 col-md-12"
                          >License Valid In</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="license_country_id"
                              :value="profile.license_country_id"
                              :options="
                                options && options.countryInfo
                                  ? options.countryInfo
                                  : []
                              "
                              @input="handleChangeSelectCustom"
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_license_authority"
                          class="col-lg-12 col-md-12"
                          >Medical License Board</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider v-slot="{ errors }">
                            <Select
                              name="issue_authority_id"
                              :value="profile.issue_authority_id"
                              :options="
                                options && options['issue_authority_id']
                                  ? options['issue_authority_id']
                                  : []
                              "
                              @input="handleChangeSelect"
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow v-if="other_authority">
                    <CCol md="6">
                      <CRow name="other_issue_authority" class="row mb-3">
                        <label class="required col-lg-12 col-md-12"
                          >Other Issuing Authority</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="other_issue_authority"
                              :value="profile.other_issue_authority"
                              @input="handleInput"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol v-if="!isAHP" md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_nursing_level"
                          class="col-lg-12 col-md-12"
                          >Level / Type</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <Select
                            name="level_id"
                            :value="profile.level_id"
                            :options="
                              options && options['candidateLevel']
                                ? options['candidateLevel']
                                : []
                            "
                            @input="handleChangeSelect"
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :showTooltip="true"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="lbl_license_number"
                          class="col-lg-12 col-md-12"
                          >License Number</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <TextInput
                            name="license_number"
                            :value="profile.license_number"
                            @change="handleInput"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <ValidationProvider name="dob" v-slot="{ errors }">
                          <TextInput
                            hidden
                            name="dob"
                            :value="profile.dob"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                        <label name="lbl_issue_date" class="col-lg-12 col-md-12"
                          >Issue / Registration Date</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            name="issue_date"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              :onlyMonthAndYear="true"
                              name="License_issue_date"
                              :value="profile.License_issue_date"
                              @change="handleDatePickerChange"
                              ref="license_issueDate"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="lbl_local_license"
                          class="col-lg-12 col-md-12"
                          >Is License Active?</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="local_license"
                            :value="profile.local_license"
                            :options="
                              options && options['local_license']
                                ? options['local_license']
                                : []
                            "
                            @change="handleChangeRadio"
                          />
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="6" v-if="!(isNurse || isAHP)">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="lbl_specialist_registration"
                          class="col-lg-12 col-md-12"
                          >Specialist Registration</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="specialist_registration"
                            :value="profile.specialist_registration"
                            :options="
                              options && options['specialist_registration']
                                ? options['specialist_registration']
                                : []
                            "
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6" v-if="profile.specialist_registration">
                      <CRow class="d-flex align-items-center mb-3">
                        <label
                          name="lbl_Date_acquired"
                          class="required col-lg-12 col-md-12"
                          >Specialist Registration Month / Year</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            name="issue_date"
                            rules="date_validate|required"
                            v-slot="{ errors }"
                          >
                            <DateInput
                              :onlyMonthAndYear="true"
                              name="date_acquired"
                              :value="profile.date_acquired"
                              @change="handleDatePickerChange"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <div class="p-1">
                        <h6 class="mb-2">
                          Select the Document to be mapped with the License :
                        </h6>
                        <div
                          class="mb-1"
                          style="
                            font-weight: 600;
                            background-color: lightgrey;
                            height: 50px;
                          "
                          v-if="filteredDocuments.length"
                        >
                          <CRow class="ml-2">
                            <div class="doc-table col col-1"></div>
                            <div class="doc-table col col-3">
                              <p class="head text-danger">Document Type</p>
                            </div>
                            <div class="doc-table col col-4">
                              <p class="head text-danger">Document Name</p>
                            </div>
                            <div class="doc-table col col-3">
                              <p class="head text-danger">Uploaded Date</p>
                            </div>
                          </CRow>
                        </div>
                        <div
                          v-for="i in filteredDocuments"
                          :key="i.document_id"
                          style="background-color: whitesmoke"
                          class="mt-1 border-bottom"
                          :style="`${
                            profile.document_ids.includes(i.document_id)
                              ? 'background-color: #D0F0C0'
                              : 'background-color: whitesmoke'
                          }`"
                        >
                          <CRow class="ml-2">
                            <div class="doc-table col col-1">
                              <p class="head mt-1">
                                <input
                                  type="checkbox"
                                  :style="'cursor:pointer'"
                                  :value="i.document_id"
                                  v-model="profile.document_ids"
                                />
                              </p>
                            </div>
                            <div class="doc-table col col-3">
                              <p class="data mt-1">{{ i.label }}</p>
                            </div>
                            <div class="doc-table col col-4">
                              <p class="data mt-1">{{ i.name }}</p>
                            </div>
                            <div class="doc-table col col-3">
                              <p class="data mt-1">{{ i.updatedDate }}</p>
                            </div>
                          </CRow>
                        </div>
                        <div
                          v-if="!filteredDocuments.length"
                          :style="
                            isMobile
                              ? 'background-color: whitesmoke; height: 65px'
                              : 'background-color: whitesmoke; height: 40px'
                          "
                        >
                          <h6
                            class="text-center mt-2 p-2"
                            :style="
                              isMobile ? 'font-size: 13px' : 'font-size: 15px'
                            "
                          >
                            No document found - you may upload and link this
                            document in Document Information section
                          </h6>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CTab>
        </CTabs>
      </div>
    </CModal>
  </div>
</template>
<script>
import moment from "moment";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditSection from "./EditSection";
import { mapActions, mapGetters } from "vuex";
import { extend, validate } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import m from "moment";
import Vue from "vue";
extend("required", { ...required, message: "This field is required" });
extend("singleRequired", {
  ...required,
  message: "Please input atleast one of the fields!",
});

extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
export default {
  mixins: [EditSection],
  props: {
    licenseInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    candidateInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      profile: {
        document_ids: [],
        level_id: null,
        issue_authority_id: null,
      },
      errors: [],
      isEdit: false,
      isMobile: false,
      other_authority: false,
      candidateLevelByTypeCountry: [],
    };
  },
  computed: {
    ...mapGetters([
      "locationCountryInfo",
      "licenseIssuedByCountry",
      "getLicense",
      "getUploadedDocument",
      "candidateLevelFilterByTypeCountry",
      "getOrgCountryId",
      "getCandidateLevel",
      "isFetchingFilter",
    ]),
    documents() {
      return (
        this.getUploadedDocument?.length &&
        this.getUploadedDocument
          .filter((val) => val.document_type_id == 9)
          .map(
            ({
              document_name,
              document_type,
              created_on,
              candidate_document_id,
              candidate_license_id,
            }) => ({
              label: document_type.comments,
              name: document_name,
              updatedDate: moment(created_on).format("DD-MM-YYYY"),
              document_id: candidate_document_id,
              candidate_license_id: candidate_license_id,
            })
          )
      );
    },
    filteredDocuments() {
      if (this.documents?.length) {
        if (this.licenseInfo)
          return this.documents?.filter(
            (val) =>
              val.candidate_license_id ==
                this.licenseInfo.candidate_license_id ||
              val.candidate_license_id == null
          );
        return this.documents?.filter(
          (val) =>
            val.candidate_license_id == null &&
            val.candidate_qualification_id == null
        );
      }
      return [];
    },
    options() {
      return {
        local_license: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        specialist_registration: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        // issue_authority_id:
        //   this.licenseIssuedByCountry({
        //     country_id: this.profile?.license_country_id?.code,
        //   }) || [],
        issue_authority_id:
          this.getFilteredIssueAuthorityByCandidateTypeAndCountry || [],
        countryInfo: this.locationCountryInfo || [],
        candidateLevel: this.candidateLevelByTypeCountry,
      };
    },
    isNurse() {
      return this.candidateInfo?.candidate_type_id
        ? this.candidateInfo?.candidate_type_id === 2
        : false;
    },
    isAHP() {
      return this.candidateInfo?.candidate_type_id
        ? this.candidateInfo?.candidate_type_id === 3
        : false;
    },
  },
  watch: {
    "profile.issue_authority_id"() {
      return this.profile?.issue_authority_id?.code == "Others"
        ? (this.other_authority = true)
        : (this.other_authority = false);
    },
    "profile.specialist_registration"() {
      if (
        this.profile?.license_country_id?.code == 182 &&
        this.profile?.specialist_registration == true &&
        this.options["candidateLevel"]?.length
      ) {
        let level = _.filter(
          this.options["candidateLevel"],
          (option) => option.code == 58
        );
        this.profile.level_id = level[0];
      } else if (
        this.profile?.license_country_id?.code == 182 &&
        this.profile?.level_id?.code == 58 &&
        this.profile.specialist_registration == false
      ) {
        this.profile.level_id = null;
      }
      if (!this.profile.specialist_registration) {
        this.profile.date_acquired = null;
      }
    },
    "profile.level_id"() {
      if (
        this.profile?.level_id?.code == 58 &&
        this.profile?.specialist_registration == false &&
        this.profile.license_country_id?.code == 182
      ) {
        this.profile.specialist_registration = true;
      } else if (
        this.profile?.level_id?.code != 58 &&
        this.profile.specialist_registration == true &&
        this.profile.license_country_id?.code == 182
      ) {
        this.profile.specialist_registration = null;
      }
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "initFetchLicenseInfoOptions",
      "candidateLevel",
      "fetchIssueAuthorityByParams",
    ]),
    handleChangeSelectCustom(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      return this.fetchAuthority();
    },
    async fetchAuthority() {
      if (this.profile?.license_country_id?.code) {
        if (!this.getCandidateLevel?.length) {
          this.candidateLevel().then((res) => {
            this.candidateLevelByTypeCountry =
              this.candidateLevelFilterByTypeCountry({
                candidateType: this.candidateInfo?.candidate_type_id,
                country_id: this.profile?.license_country_id?.code,
              }) || [];
          });
        } else {
          this.candidateLevelByTypeCountry =
            this.candidateLevelFilterByTypeCountry({
              candidateType: this.candidateInfo?.candidate_type_id,
              country_id: this.profile?.license_country_id?.code,
            }) || [];
        }
        let issueAuthorityPayload = {
          candidate_type_id: this.candidateInfo?.candidate_type_id,
          country_id: this.profile.license_country_id.code,
          is_licensing: true,
        };
        await this.fetchIssueAuthorityByParams(issueAuthorityPayload).then(
          (res) => {
            if (
              this.profile?.license_country_id?.code == 182 &&
              this.candidateInfo?.candidate_type_id == 1 &&
              this.options["issue_authority_id"]?.length &&
              !this.profile?.issue_authority_id
            ) {
              let authority = _.filter(
                this.options["issue_authority_id"],
                (option) => option.code == 1
              );
              this.profile.issue_authority_id = authority[0];
            } else {
              this.profile.issue_authority_id = null;
            }
          }
        );
        if (
          this.profile?.license_country_id?.code == 182 &&
          this.profile?.specialist_registration == true &&
          this.options["candidateLevel"]?.length
        ) {
          let level = _.filter(
            this.options["candidateLevel"],
            (option) => option.code == 58
          );
          this.profile.level_id = level[0];
        }
      } else {
        this.candidateLevelByTypeCountry = [];
        this.options["issue_authority_id"] = [];
      }
      return;
    },
    async onSubmit() {
      const isValid = await this.$refs.editLicenseInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let issueAuthorityExist = 0;
      let issue_auth_code = this.profile?.issue_authority_id?.code;
      let license_country_id = this.profile?.license_country_id?.code;
      this.getLicense?.forEach((val) => {
        if (!val?.issue_authority_id && !issue_auth_code) return;
        val?.country_id == license_country_id &&
        val?.issue_authority_id == issue_auth_code
          ? (issueAuthorityExist = issueAuthorityExist + 1)
          : null;
        if (val.candidate_license_id == this.profile.candidate_license_id) {
          if (val?.issue_authority_id == issue_auth_code)
            issueAuthorityExist
              ? (issueAuthorityExist = issueAuthorityExist - 1)
              : null;
        }
      });
      if (issueAuthorityExist) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Requested Medical License Board already exist!",
          autohide: 1500,
        });
        let errorMsg = {
          modalTitle: "Duplicate Record",
          modalColor: "primary",
          modalContent:
            "Requested Candidate License already exist! Please edit the existing record!",
          isShowPopup: true,
        };
        this.$emit("licenseModalShow", false);
        this.$store.commit("SHOW_ERROR_MSG_MODAL", errorMsg);
        return;
      }
      let specialist_registration_year = null;
      let specialist_registration_month = null;
      if (this.profile.date_acquired) {
        let date_acquired = this.profile.date_acquired.split("-");
        specialist_registration_year = date_acquired[0];
        specialist_registration_month = date_acquired[1];
      }
      let LicenseInfoPayload = {
        candidate_license_id: this.profile.candidate_license_id,
        candidate_uid: this.candidateInfo?.candidate_uid,
        license_number: this.profile?.license_number,
        country_id:
          this.profile?.license_country_id !== null
            ? this.profile?.license_country_id?.code
            : null,
        issue_date: this.profile?.License_issue_date
          ? this.profile?.License_issue_date
          : null,
        speciality_id: this.candidateInfo.speciality_id,
        sub_speciality_id: this.candidateInfo.sub_speciality_id,
        level_id:
          this.profile?.level_id !== null ? this.profile?.level_id?.code : null,
        issue_authority_id: this.profile.issue_authority_id
          ? this.profile?.issue_authority_id?.code == "Others"
            ? null
            : this.profile?.issue_authority_id?.code
          : null,
        current_license:
          this.profile.local_license !== null
            ? this.profile?.local_license
            : null,
        specialist_registration:
          this.profile?.specialist_registration !== null
            ? this.profile?.specialist_registration
            : null,
        specialist_registration_year,
        specialist_registration_month,
        document_ids: this.profile.document_ids.length
          ? this.profile.document_ids
          : [],
        other_issue_authority: this.profile.issue_authority_id
          ? this.profile?.issue_authority_id?.code == "Others"
            ? this.profile?.other_issue_authority
            : null
          : null,
      };
      this.modalCallBack(true, LicenseInfoPayload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    populateData() {
      let data = this.licenseInfo;
      this.profile = {
        candidate_license_id: data.candidate_license_id,
        license_number: data.license_number,
        License_issue_date: data.issue_date,
        local_license: data.current_license,
        specialist_registration: data.specialist_registration,
        date_acquired: data.date_acquired,
        document_ids: data.document_ids ? data.document_ids : [],
        documents: data.documents ? data.documents : [],
        other_issue_authority: data?.other_issue_authority,
        license_country_id: {
          code: data.country_id,
          label: data.country_name,
        },
      };
      this.fetchAuthority().then((res) => {
        this.handleChangeSelect("issue_authority_id", {
          code:
            data.issue_authority_id !== "--" ? data.issue_authority_id : null,
          label:
            data.issue_authority_name !== "--"
              ? data.issue_authority_name
              : null,
        });
        this.handleChangeSelect("level_id", {
          code: data.level_id,
          label: data.level_name,
        });
      });
      // this.handleChangeSelectCustom("license_country_id", {
      //   code: data.country_id,
      //   label: data.country_name,
      // });
      // this.fetchAuthority();
    },
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
    this.initFetchLicenseInfoOptions().then((res) => {
      if (isObject(this.licenseInfo) && !isEmptyObjectCheck(this.licenseInfo)) {
        this.isEdit = true;
        this.populateData();
      } else {
        this.isEdit = false;
      }
    });
  },
};
</script>
